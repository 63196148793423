<template>
  <b-card v-if="data">
    <b-dropdown
      variant="link"
      no-caret
      class="chart-dropdown"
      toggle-class="p-0"
      style="float: right"
    >
      <template #button-content>
        <feather-icon
          icon="MoreVerticalIcon"
          size="18"
          class="text-body cursor-pointer"
        />
      </template>
      <b-dropdown-item href="#">
        Last 7 Days
      </b-dropdown-item>
      <b-dropdown-item href="#">
        Last 30 Days
      </b-dropdown-item>
    </b-dropdown>
    <b-row class="pb-50">

      <!-- text and button -->
      <b-col
        sm="6"
        order-sm="1"
        order="2"
        class="d-flex justify-content-between flex-column mt-1 mt-sm-0"
      >
        <div>
          <h2 class="font-weight-bolder mb-25">
            {{ kFormatter(datas.sessions) }}
          </h2>
          <b-card-text class="font-weight-bold mb-2">
            活跃信息
          </b-card-text>

          <h5 class="font-medium-2">
            <span class="text-success mr-50">{{ datas.growth }}</span>
            <span>过去 7 天/ 30 天 活跃成员 和 不活跃成员 绿色代表活跃 红色代表不活跃</span>
          </h5>
        </div>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="shadow"
          to="/general/member/member_list"
        >
          <span>查看详情 </span>
          <feather-icon icon="ChevronsRightIcon" />
        </b-button>
      </b-col>

      <!-- dropdown and chart -->
      <b-col
        sm="6"
        cols="12"
        order-sm="2"
        order="1"
        class="d-flex justify-content-between flex-column text-right"
      >
        <!-- apex chart -->
        <vue-apex-charts
          type="bar"
          height="200"
          width="200"
          :options="salesBar.chartOptions"
          :series="datas.salesBar.series"
        />
      </b-col>
    </b-row>
    <hr>
    <!-- progress bar -->
    <b-row class="avg-sessions pt-50">
      <b-col cols="6">
        <b-card-text class="mb-50">
          活跃: {{ this.data.series[0].data }}
        </b-card-text>
        <b-progress
          value="70"
          max="100"
          height="6px"
          variant="danger"
          class="mt-25"
        />
      </b-col>
      <b-col cols="6">
        <b-card-text class="mb-50">
          不活跃: {{ this.data.series[0].no_data }}
        </b-card-text>
        <b-progress
          value="90"
          max="100"
          variant="success"
          height="6px"
          class="mt-25"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BCardText, BProgress
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'
import { kFormatter } from '@core/utils/filter'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BProgress,
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BButton,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      datas: {
        sessions: '',
        growth: '',
        goal: '',
        users: '',
        retention: '',
        duration: '',
        salesBar: {
          series: [

          ]
        }
      },
      salesBar: {
        chartOptions: {
          chart: {
            sparkline: { enabled: true },
            toolbar: { show: false },
          },
          color: ['#12b376', '#f00'],
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
            },
          },
          states: {
            hover: {
              filter: 'none',
            },
          },
          colors: [
            '#12b376',
            '#f00',
          ],
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
              endingShape: 'rounded',
            },
          },
          tooltip: {
            x: { show: false },
          },
          xaxis: {
            type: 'numeric',
          },
        },
      },
    }
  },
  mounted() {
    // axiosIns.get('/user/activeUserList').then(res => {
    //   console.log();
    // })
    // this.getData()
    console.log(this.data.series[0].data,132132123132)
    setTimeout(() => {
      this.datas = {
        sessions: 2700,
        growth: '+5.2%',
        goal: 100000,
        users: 100000,
        retention: this.data.series[0].data,
        duration: this.data.series[0].no_data,
        salesBar: {
          series: this.data.series[0].list
        }
      }
    }, 2000)
  },
  methods: {
    kFormatter,
    async getData() {
      await axiosIns.get('/user/activeUserList', {}).then(res => {
        console.log(res.data, '-----------------------')
      })
    },
  },
}
</script>
