<template>
  <b-card
    text-variant="center"
    class="card card-congratulations"
  >
    <!-- images -->
    <b-img
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
    />
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
    <template v-if="data.length > 0">
      <b-avatar
        style="margin: 80px auto;"
        variant="primary"
        size="70"
        class="shadow mb-2"
        v-for="(item,index) in data"
        :key="item.id"
        :src="item.avatar"
        v-show="activeIndex == index"
      />
      <div>{{ data[activeIndex].real_name }}祝你生日快乐,Happy Birthday To You</div>
    </template>
    <template v-else>
      <b-avatar
        style="margin-top: 120px;"
        variant="primary"
        size="70"
        class="shadow mb-2"
      >
        <feather-icon
          size="28"
          icon="AwardIcon"
        />
      </b-avatar>
      <div>暂无今日会员</div>
    </template>

    <h1 class="mb-1 mt-50 text-white">
      <!-- Congratulations {{ data.name || '暂无消息' }}, -->
    </h1>
    <b-card-text class="m-auto w-75" />
  </b-card>
</template>

<script>
import {
  BCard, BImg, BAvatar, BCardText,
} from 'bootstrap-vue'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
  },
  props: {
    data: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  data: (() => ({
    activeIndex: 0,
  })),
  mounted() {
    axiosIns.get('/index/todayBirthUser').then(res => {
      this.today = res.data.today
    })
    setInterval(() => {
      this.activeIndex++
      if (this.activeIndex >= this.today.length) {
        this.activeIndex = 0
      }
    }, 3000)
  }
}
</script>

<style lang="scss">
.card-congratulations {
  background: linear-gradient(118deg, rgba(119, 107, 241, 1), rgba(153, 144, 242, 0.7));
  color: #fff;

  // image on left
  .congratulations-img-left {
    width: 200px;
    position: absolute;
    top: 0;
    left: 0;

      width: 140px;
  }
  // image on right
  .congratulations-img-right {
    width: 175px;
    position: absolute;
    top: 0;
    right: 0;

      width: 140px;
  }

  .avatar {
    margin-bottom: 2rem;
  }
}
</style>
