<!-- @format -->

<template>
  <section>
    <b-row>
      <b-col style="padding: 20px">
        <b-button variant="primary" to="/general/policy/policy_list/create">保单提交</b-button>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col xl="4" md="6">
        <l1 :data="data.congratulations" />
      </b-col>
      <b-col xl="8" md="6">
        <l2 :data="data.statisticsItems" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <b-col lg="6" md="3" cols="6">
            <ll1 :data="data.statisticsOrder" v-if="data.statisticsOrder.series[0].data.length > 0" />
          </b-col>
          <b-col lg="6" md="3" cols="6">
            <ll2 :data="data.statisticsProfit" v-if="data.statisticsProfit.series[0].data.length > 0" />
          </b-col>
          <b-col lg="12" md="6">
            <ll3 :data="data.earningsChart" />
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="8">
        <rc1 :data="data.revenue" />
      </b-col>
    </b-row>
    <b-row class="match-height" lg="12">
      <b-col>
        <ecommerce-member :data="data.todayBirthUser" />
      </b-col>
      <b-col lg="12" md="12" xl="3">
        <ecommerce-order-chart :chart-data="data.statisticsOrder.series" statistic="Total commission receivable" statistic-title="12312k总应收佣金" />
      </b-col>
      <b-col lg="12" md="12" xl="3">
        <ecommerce-order-chart :chart-data="data.statisticsProfit.series" statistic="Total commission for performance" statistic-title="12312k总应收佣金" color="warning" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="12" md="12" xl="6">
        <AnalyticsAvgSessions :data="data.sessionsData" v-if="data.sessionsData.series[0].list.length > 0" />
      </b-col>
      <b-col lg="12" md="12" xl="6">
        <AnalyticsAvgSessionsTracker :data="data.TrackerData" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <!-- Browser States Card -->
      <b-col lg="4" md="6">
<!--        <b1 />-->
      </b-col>
      <!--/ Browser States Card -->
    </b-row>
  </section>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'

import axios from '@/libs/axios'
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import { ref, onMounted, getCurrentInstance } from '@vue/composition-api'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceMember from './EcommerceMember.vue'
import AnalyticsAvgSessions from './AnalyticsAvgSessions.vue'
import AnalyticsAvgSessionsTracker from './AnalyticsSupportTracker.vue'

import l1 from './l1.vue'
import l2 from './l2.vue'
import ll1 from './ll1.vue'
import ll2 from './ll2.vue'
import ll3 from './ll3.vue'
import rc1 from './rc1.vue'
import b1 from './b1.vue'

export default {
  components: {
    l1,
    l2,
    ll1,
    ll2,
    ll3,
    rc1,
    b1,
    BRow,
    BCol,
    EcommerceMember,
    EcommerceOrderChart,
    AnalyticsAvgSessions,
    AnalyticsAvgSessionsTracker,
  },
  data() {
    return {
      main_all: {},
      address: '',
      password_hash: '',
      real_name: '',
      gender: 0,
      sex: [
        {
          label: '男',
          value: 1,
        },
        {
          label: '女',
          value: 2,
        },
      ],
      imgurl: '',
      data: {
        statisticsOrder: {
          series: [],
        },
        statisticsProfit: {
          series: [],
        },
        sessionsData: {
          series: [],
        },
        TrackerData: {
          series: [],
        },
      },
      childUserData: [],
      baifenData: [],
      userDatas: JSON.parse(localStorage.getItem('userData')),
      id: JSON.parse(localStorage.getItem('first_login')),
    }
  },

  setup() {
    // eslint-disable-next-line
    const { proxy } = getCurrentInstance()

    const refInputEl = ref(null)
    const imgdata = ref(null)
    const childUserData = ref(null)
    const baifenData = ref(null)
    onMounted(() => {
      useJwt.index_head().then(response => {
        childUserData.value = response.data
      })
      useJwt.baifen().then(response => {
        baifenData.value = response.data
      })
    })
    return {
      childUserData,
      baifenData,
      refInputEl,
      imgdata,
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$http.get('/ecommerce/data').then(response => {
        console.log(response, 'response')
        // this.data = response
        // const userData = getUserData()
        // this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
      if (this.id === 1) {
        this.tanchuang()
        this.userDatas.first_login = 0
        localStorage.setItem('first_login', this.userDatas.first_login)
      }
      const indexDashData = {
        todayBirthUser: [],
        congratulations: {},
        statisticsItems: [
          {
            icon: 'TrendingUpIcon',
            color: 'light-primary',
            title: '230k',
            subtitle: '团队数量',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'UserIcon',
            color: 'light-info',
            title: '8.549k',
            subtitle: '上级数量',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'BoxIcon',
            color: 'light-danger',
            title: '1.423k',
            subtitle: '下级数量',
            customClass: 'mb-2 mb-sm-0',
          },
        ],
        statisticsOrder: {
          series: [
            {
              name: '2020',
              effect_tp: '',
              data: [],
            },
          ],
        },
        statisticsProfit: {
          series: [
            {
              data: [],
              no_effect_tp: '',
            },
          ],
        },
        sessionsData: {
          series: [
            {
              data: [],
              no_data: [],
              total: '',
              list: [
                {
                  name: '123',
                  data: [],
                },
              ],
            },
          ],
        },
        TrackerData: {
          series: [
            {
              percent: [],
              not_ok_order: '',
              is_ok_order: '',
              my_team_order: '',
              total_order: '',
            },
          ],
        },
        earningsChart: {
          data: {
            allTotal: 0,
            percent: undefined,
          },
          series: [53, 16, 31],
        },
        revenue: {
          years: ['2020', '2019', '2018'],
          price: '25,852',
          budget: '56,800',
          revenueReport: {
            series: [
              {
                name: '收入',
                data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
              },
              {
                name: '费用',
                data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
              },
            ],
          },
          budgetChart: {
            series: [
              {
                data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
              },
              {
                data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
              },
            ],
          },
        },
      }
      this.$nextTick(() => {
        axios.get('index/index').then(response => {
          console.log(response.data)
          indexDashData.TrackerData.series[0].total_order = response.data.orderInfo.total_order // 个人订单总订单数
          indexDashData.TrackerData.series[0].not_ok_order = response.data.orderInfo.not_ok_order
          indexDashData.TrackerData.series[0].is_ok_order = response.data.orderInfo.is_ok_order
          indexDashData.TrackerData.series[0].my_team_order = response.data.orderInfo.my_team_order
          indexDashData.TrackerData.series[0].percent = response.data.orderInfo.percent

          indexDashData.statisticsOrder.series[0].effect_tp = response.data.tpInfo.effect_tp // 已生效
          indexDashData.statisticsProfit.series[0].no_effect_tp = response.data.tpInfo.no_effect_tp // 未生效
          indexDashData.sessionsData.series[0].data = response.data.activeUserInfo.active_num // 活跃
          indexDashData.sessionsData.series[0].no_data = response.data.activeUserInfo.not_active_num // 不活跃
          indexDashData.sessionsData.series[0].total = response.data.activeUserInfo.total_num // 综合活跃数
          indexDashData.sessionsData.series[0].list[0].data[0] = response.data.activeUserInfo.active_num
          indexDashData.sessionsData.series[0].list[0].data[1] = response.data.activeUserInfo.not_active_num
          indexDashData.congratulations = response.data.userInfo // 用户信息等级信息
          indexDashData.statisticsOrder.series[0].data = (item => {
            // 不足5位补0
            // 数组长度不足5位补0
            if (item.length < 5) {
              const arr = []
              for (let i = 0; i < 5 - item.length; i++) {
                arr.push(0)
              }
              return arr.concat(Number(item))
            }
            return item
          })(response.data.tpInfo.effect_tp_list) // 利润数据
          console.log(indexDashData.statisticsOrder.series)

          indexDashData.statisticsProfit.series[0].data = (item => {
            // 不足5位补0
            // 数组长度不足5位补0
            if (item.length < 5) {
              const arr = []
              for (let i = 0; i < 5 - item.length; i++) {
                arr.push(0)
              }
              return arr.concat(Number(item))
            }
            return item
          })(response.data.tpInfo.not_effect_tp_list) // no利润数据
          indexDashData.earningsChart.data.allTotal = response.data.moneyInfo.the_month_total // 月收入佣金
          indexDashData.earningsChart.data.percent = response.data.moneyInfo.percent // 月收入佣金百分比

          indexDashData.todayBirthUser = response.data.todayBirthUser // 今日生日用户
        })
        this.data = indexDashData
      })

      // useJwt.index_head().then(response => {
      //   this.childUserData = response.data
      // })
      // useJwt.baifen().then(response => {
      //   this.baifenData = response.data
      // })
      // axios.get('user/userData').then(response => {
      //   this.main_all = response.data
      // })
    })
  },
  methods: {
    tanchuang() {
      this.$bvModal.show('audit-modal')
    },
    inputImageRenderer(e) {
      console.log(e)
      const files = e.files[0]
      const formData = new FormData()
      formData.append('image', files)
      axios.post('/upload/uploadAvatar', formData).then(res => {
        this.imgurl = process.env.VUE_APP_BASE_URL.replace('/backend', '/') + res.data.url
      })
    },
    submitChange() {
      axios
        .post('user/loginEditInfo', {
          password_hash: this.password_hash,
          gender: this.gender.value,
          address: this.address,
          avatar: this.imgurl,
          real_name: this.real_name,
        })
        .then(res => {
          if (res.code <= 0) {
            this.$bvToast.toast(res.msg, {
              autoHideDelay: 2000,
              appendToast: true,
              autoHide: true,
              toastClass: 'bg-success text-white',
              title: '修改成功',
            })
          } else {
            this.$bvToast.toast(res.msg, {
              autoHideDelay: 2000,
              appendToast: true,
              autoHide: true,
              toastClass: 'bg-danger text-white',
              title: '修改失败',
            })
          }
        })
        .catch(err => {
          this.$bvToast.toast(err.msg || '修改失败', {
            autoHideDelay: 2000,
            appendToast: true,
            autoHide: true,
            toastClass: 'bg-danger text-white',
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
