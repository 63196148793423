<template>
  <b-card
    v-if="data"
    class="card-congratulation-medal"
  >
    <h5>祝贺 🎉 {{ data.real_name }}!</h5>
    <b-card-text class="font-small-3">
      您目前的等级为 {{ data.level.name }} 级!
    </b-card-text>
    <h3 class="mb-75 mt-2 pt-50">
      距离下一等级还差<b-link> {{ kFormatter(data.next_level_info.needTp) }} </b-link>TP值
    </h3>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      to="level_detail"
    >
      等级说明
    </b-button>
    <b-img
      :src="require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BLink, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    kFormatter,
  },
}
</script>
